import { SearchClient } from 'typesense'

export const createTypesenseClient = (apiKey: string) =>
  new SearchClient({
    apiKey,
    nodes: [
      {
        host:
          process.env.NEXT_PUBLIC_BACKEND_ENV == 'sandbox'
            ? 'aj95kyb2pczfinrhp-1.a1.typesense.net'
            : 'le263qi7x1pbtfgmp.a1.typesense.net',
        protocol: 'https',
        port: 443,
      },
    ],
  })
