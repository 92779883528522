import { SWRConfiguration } from 'swr'
import useSWRInfinite from 'swr/infinite'
import { useMemo, useCallback, useRef, useEffect } from 'react'
import last from 'lodash.last'
import get from 'lodash.get'
import { useFreezeSwrStateOnBlur } from '@beatgig/native/freeze-state-on-blur/swr'
import { Sentry } from '@beatgig/helpers/sentry'
import useStable from '@beatgig/design/hooks/use-stable'

type PageKeyMaker<Page, Key extends any[]> = (
  index: number,
  previousPageData?: Page
  /**
   * Mutable ref object. Set this to `true` before the request and `false` afterwards if the request is fetching more.
   *
   * For example, if the request has a `lastDocId`, it should set it to `true` before fetching.
   *
   * This prevents multiple page increases at once.
   */
) => Key | null

type SWRInfiniteConfiguration<Data = any, Error = any> = SWRConfiguration<
  Data[],
  Error
> & {
  initialSize?: number
  revalidateAll?: boolean
  persistSize?: boolean
}

export type UseGetInfinitePagesConfig<
  Page extends object
> = SWRInfiniteConfiguration<Page> & {
  limit?: number
  /**
   * path to the list. For example, if your pages look like this:
   *
   * {
   *   hits: [...hits]
   * }
   *
   * then data path should be `hits`.
   *
   * If your pages are just flat arrays, pass `null`.
   *
   * For nested fields, pass an array string path.
   */
  dataPath: keyof Page | string[] | null
}

type PageFetcher<Page, Key extends any[]> = (
  ...params: NonNullable<Key>
) => Page | Promise<Page>

/**
 * TODO make this all type on its own somehow.
 * @param key
 * @param fetcher
 * @param param2
 * @returns
 */
const useGetInfinitePages = <
  Page extends object,
  Data,
  /**
   * Path to your list data
   */
  Key extends any[] = any[]
>(
  key: PageKeyMaker<Page, Key>,
  fetcher: PageFetcher<Page, Key>,
  { limit = 20, dataPath: path, ...options }: UseGetInfinitePagesConfig<Page>
) => {
  const isFetching = useRef(false)
  const dataPath = Array.isArray(path) ? path.join('.') : path
  const { overrideState, setCachedState } = useFreezeSwrStateOnBlur<Page[]>()

  const isPullingToRefreshRef = useRef(false)

  const response = useSWRInfinite<Page>(
    (index, previousPage) => {
      if (overrideState) return null

      const previousPageData = dataPath
        ? get(previousPage, dataPath)
        : previousPage
      // we've reached the last page, no more fetching
      if (previousPageData?.length === 0) return null
      // TODO is this correct?
      // this means we haven't fetched the previous page yet, so don't fetch multiple at once.
      // if (index > 0 && !previousPageData) return null
      if (isFetching.current && index) return null
      if (previousPageData && previousPageData.length < limit) {
        return null
      }

      return key(index, previousPageData)
    },
    async (...key: NonNullable<Key>) => {
      let val: Page
      try {
        isFetching.current = true
        val = await fetcher(...key)
        if (isFetching.current) {
          isFetching.current = false
        }
      } catch (e) {
        if (isFetching.current) {
          isFetching.current = false
        }
        throw e
      }

      return val
    },
    {
      revalidateAll: false,
      ...options,
      onSuccess(...props) {
        isPullingToRefreshRef.current = false
        options.onSuccess?.(...props)
      },
      onError(...props) {
        isPullingToRefreshRef.current = false
        options.onError?.(...props)
      },
    }
  )
  const { data: swrData, mutate, size, setSize } = response
  if (swrData) {
    setCachedState(swrData)
  }

  const responseRef = useRef(response)
  responseRef.current = response

  const loadingStates: {
    isLoadingInitialData: boolean
    isLoadingMore: boolean
    // isRefreshing: boolean
    isPullingToRefresh: boolean
    isFetchingMore: boolean
  } = {
    get isLoadingInitialData() {
      return !data && !response.error
    },
    get isLoadingMore() {
      return (
        this.isLoadingInitialData ||
        !!(
          response.isValidating &&
          size > 1 &&
          data &&
          typeof data[size - 1] === 'undefined'
        )
      )
    },
    // get isRefreshing() {
    //   return response.isValidating && data?.length === size
    // },
    get isPullingToRefresh() {
      return response.isValidating && isPullingToRefreshRef.current
    },
    get isFetchingMore() {
      return this.isLoadingMore
    },
  }

  const loadingStateRefs = useRef(loadingStates)
  loadingStateRefs.current = loadingStates

  const data = overrideState || swrData

  const firstPageData = dataPath ? get(data?.[0], dataPath) : data?.[0]
  const lastPage = last(data)
  const lastPageData = dataPath ? get(lastPage, dataPath) : lastPage
  const canFetchMore = !!(lastPageData?.length && lastPageData.length === limit)
  // const isLoadingInitialData = !data && !response.error

  // const isLoadingMore =
  //   isLoadingInitialData ||
  //   !!(
  //     isValidating &&
  //     size > 1 &&
  //     data &&
  //     typeof data[size - 1] === 'undefined'
  //   )

  // const isRefreshing = isValidating && data?.length === size
  const isEmpty = firstPageData?.length === 0

  const canFetchMoreRef = useStable(canFetchMore)

  const fetchMore = useCallback(() => {
    if (
      loadingStateRefs.current.isLoadingMore ||
      isFetching.current ||
      !canFetchMoreRef.current
    ) {
      return
    }

    setSize((size) => {
      return size + 1
    })
  }, [setSize, canFetchMoreRef])

  const flat = useMemo(() => {
    if (!data) {
      return undefined
    }
    if (!Array.isArray(data)) {
      Sentry.captureException('[use-get-infinite-pages] not an array!', data)
      return undefined
    }
    return data
      ?.map((page) => (dataPath ? get(page, dataPath) : page) as Data)
      ?.flat(1)
      .filter(Boolean) as
      | (Data extends readonly (infer InnerArr)[] ? InnerArr : Data)[]
      | undefined
  }, [data, dataPath])

  const pullToRefresh = useCallback(() => {
    isPullingToRefreshRef.current = true
    mutate()
  }, [mutate])

  // const wasRevalidating = useRef(isValidating)

  useEffect(function disablePullingToRefreshOnStopRevalidating() {
    if (isPullingToRefreshRef.current) {
      if (!response.isValidating) {
        isPullingToRefreshRef.current = false
      }
    }
  })

  return Object.assign(
    {},
    {
      data: flat,
      get pages() {
        return data
      },
      // error,
      // isValidating,
      mutate,
      fetchMore,
      // isFetchingMore: !!isLoadingMore,
      // isRefreshing,
      isEmpty,
      // isLoadingInitialData,
      // isLoadingMore,
      lastPage,
      size,
      revalidate: useCallback(() => {
        return mutate()
      }, [mutate]),
      canFetchMore,
      pullToRefresh,
      // get isPullingToRefresh() {
      //   return isPullingToRefreshRef.current && isValidating
      // },
    },
    {
      get error() {
        return response.error
      },
      get isValidating() {
        return response.isValidating
      },
    },
    loadingStates
  )
}

export default useGetInfinitePages
