import { UseFreezeSwrStateOnBlurReturns } from './swr.types'

export const useFreezeSwrStateOnBlur = <
  State
>(): UseFreezeSwrStateOnBlurReturns<State> => {
  return {
    setCachedState: () => {
      // no-op
    },
    overrideState: undefined,
  }
}
