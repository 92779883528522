import { Sentry } from '@beatgig/helpers/sentry'
import { NumericOperators } from './use-format-algolia-filters'

export function algoliaFiltersToTypesense({
  facetFilters = [],
  numericFilters = [],
  aroundLatLng,
  aroundRadius,
}: {
  facetFilters?: readonly (string | readonly string[])[]
  numericFilters?: readonly (string | readonly string[])[]
  aroundLatLng?: string
  aroundRadius?: number | 'all'
}) {
  const filterBy: Array<string> = []

  for (const facetFilter of facetFilters) {
    if (typeof facetFilter == 'string') {
      filterBy.push(facetFilter)
    } else if (Array.isArray(facetFilter) && facetFilter.length > 0) {
      const [key] = facetFilter[0].split(':')

      const values = facetFilter.map((string) => string.split(':')[1])

      // example: ["band_configuration:1", "band_configuration:2"] -> band_configuration:[1,2]
      const string = `${key}:[${values.join(',')}]`

      filterBy.push(string)
    }
  }

  for (const numericFilter of numericFilters) {
    const operatorReplacements: Record<
      Exclude<NumericOperators, '!='>,
      string
    > = {
      '<=': ':<=',
      '<': ':<',
      '>=': ':>=',
      '>': ':>',
      '=': ':=',
    }
    if (typeof numericFilter == 'string') {
      const algoliaOperator = Object.keys(operatorReplacements).find(
        (operatorString) => {
          return numericFilter.includes(operatorString)
        }
      ) as NumericOperators | undefined

      const typesenseOperator =
        algoliaOperator && operatorReplacements[algoliaOperator]

      if (!typesenseOperator) {
        const error = `Invalid numeric filter. Couldn't find a typesense operator for ${JSON.stringify(
          numericFilter
        )}`
        if (__DEV__) {
          throw new Error(error)
        } else {
          Sentry.captureMessage(error)
        }
      } else {
        filterBy.push(numericFilter.replace(algoliaOperator, typesenseOperator))
      }
    } else if (Array.isArray(numericFilter) && numericFilter.length > 0) {
      const error = `Invalid numeric filter. You passed an OR array. Why? This won't be applied: ${JSON.stringify(
        numericFilter
      )}`
      if (__DEV__) {
        throw new Error(error)
      } else {
        Sentry.captureMessage(error)
      }
    }
  }

  let locationSortBy: string | undefined
  if (aroundLatLng) {
    let radius: number | undefined
    if (aroundRadius == 'all') {
      radius = 2_000_000_000_000
    } else {
      radius = aroundRadius
    }

    const radiusKm =
      typeof radius == 'number' ? `, ${(radius / 1000).toFixed(2)} km` : ''

    locationSortBy = `location_geopoint(${aroundLatLng}):asc`
    filterBy.push(`location_geopoint:(${aroundLatLng}${radiusKm})`)
  }

  return { filterBy: filterBy.join(' && '), locationSortBy }
}
