import useGetInfinitePages, {
  UseGetInfinitePagesConfig,
} from '@beatgig/api-hooks/src/use-get-infinite-pages'
import { UseSearchInfiniteOptions as Options } from './use-search-infinite.types'
import deepEqual from 'fast-deep-equal'
import { SearchResponse, SearchResponseHit } from 'typesense'
import { algoliaFiltersToTypesense } from './algolia-to-typesense'
import { useEffect, useMemo, useRef } from 'react'
import { createTypesenseClient } from './typesense'

export type TypesenseSortLocation = `location_geopoint(${number},${number}):${
  | 'asc'
  | 'desc'}`

type UseSearchInfiniteOptions<T> = Options<T> & {
  queryBy: Extract<keyof T, string> | Array<Extract<keyof T, string>>
  sortBy:
    | Extract<keyof T, string>
    | Array<Extract<keyof T, string> | TypesenseSortLocation>
  sortOrder: 'asc' | 'desc'
  groupBy?: Extract<keyof T, string> | Array<Extract<keyof T, string>>
  groupLimit?: number
}

type TypesenseHit<T> = { document: T } & {
  objectID: string
  text_match: number
  highlights: Array<{
    field: string
    snippet: Array<string>
    matched_tokens: Array<string>
  }>
}

type SWRKeyParameters<T> = {
  query: string
  hitsPerPage: number
  page: number
  apiKey: string | null
} & Omit<UseSearchInfiniteOptions<T>, 'apiKey'>

export type UseSearchInfiniteConfig<T extends object> = Omit<
  UseGetInfinitePagesConfig<SearchResponse<T>>,
  'dataPath'
>

function useTypesenseSearchInfinite<T extends object>(
  /**
   * Query is required. You can set this to an empty string if you need to only use other filters and not a query.
   *
   * To disable fetching, set this to `null`. This allows you to not based on some condition.
   */
  queryText: string | null,
  options: Omit<UseSearchInfiniteOptions<T>, 'apiKey'> & {
    apiKey: string | null
  },
  { limit = 20, ...config }: UseSearchInfiniteConfig<T> = {}
) {
  const {
    index,
    facets,
    filters,
    facetFilters,
    numericFilters,
    aroundLatLng,
    aroundLatLngViaIP,
    aroundRadius,
    cacheable,
    queryBy,
    sortBy,
    sortOrder,
    apiKey,
    groupBy,
    groupLimit,
  } = options
  const query = queryText // TODO debounce this?

  const makeSWRKey = ({
    query,
    facets,
    filters,
    facetFilters,
    numericFilters,
    index,
    hitsPerPage,
    page,
    aroundLatLng,
    aroundLatLngViaIP,
    aroundRadius,
    cacheable,
    queryBy,
    sortBy,
    sortOrder,
    groupBy,
    groupLimit,
  }: SWRKeyParameters<T>) => {
    return [
      'algolia-search',
      query,
      index,
      JSON.stringify({
        facets,
        filters,
        facetFilters,
        numericFilters,
        hitsPerPage,
        page,
        aroundLatLng,
        aroundLatLngViaIP,
        aroundRadius,
        cacheable,
        queryBy,
        sortBy,
        sortOrder,
        groupBy,
        groupLimit,
      }),
    ]
  }
  const response = useGetInfinitePages<
    SearchResponse<T>,
    SearchResponse<T>['hits']
  >(
    (page, previousPageData) => {
      if (query === null || apiKey == null) throw new Error('do not fetch yet.')

      const key = makeSWRKey({
        query,
        facets,
        index, // algolia index
        facetFilters,
        filters,
        numericFilters,
        hitsPerPage: limit,
        page,
        aroundLatLngViaIP,
        aroundLatLng,
        aroundRadius,
        cacheable,
        queryBy,
        sortBy,
        sortOrder,
        apiKey,
        groupBy,
        groupLimit,
      })

      return key
    },
    async (_, query = '', index, facetString) => {
      const {
        // numericFilters,
        // facetFilters,
        // hitsPerPage,
        page,
        hitsPerPage,
      }: // aroundLatLng,
      // aroundRadius,
      // queryBy,
      // sortBy,
      // sortOrder,
      // groupBy,
      // groupLimit,
      Pick<
        SWRKeyParameters<T>,
        | 'facetFilters'
        | 'numericFilters'
        | 'hitsPerPage'
        | 'page'
        | 'aroundLatLng'
        | 'aroundRadius'
        | 'queryBy'
        | 'sortBy'
        | 'sortOrder'
        | 'groupBy'
        | 'groupLimit'
      > = facetString ? JSON.parse(facetString) ?? {} : undefined

      const { filterBy, locationSortBy } = algoliaFiltersToTypesense({
        numericFilters,
        facetFilters,
        aroundLatLng,
        aroundRadius,
      })

      const res = (await createTypesenseClient(apiKey as string)
        .collections<T>(index)
        .documents()
        .search(
          {
            q: query,
            page: page + 1,
            per_page: hitsPerPage,
            query_by: Array.isArray(queryBy) ? queryBy.join(',') : queryBy,
            filter_by: filterBy,
            // TODO this may not be the best way to do this...
            // also sortby should probably support arrays?
            sort_by:
              `${locationSortBy ? `${locationSortBy},` : ``}` +
              `${sortBy}:${sortOrder}`,
            group_by: Array.isArray(groupBy) ? groupBy.join(',') : groupBy,
            group_limit: groupLimit,
          },
          {}
        )) as SearchResponse<T>

      return res
    },
    {
      refreshInterval: 0,
      refreshWhenHidden: false,
      revalidateAll: false,
      revalidateOnFocus: false,
      compare(prev, next) {
        if (prev === next) return true

        const getComparableValues = (
          pages: NonNullable<typeof prev> | undefined
        ) => {
          let hits: NonNullable<typeof pages>[number]['hits'] | undefined
          let found: number[] | undefined
          let groupedHits: SearchResponse<T>['grouped_hits'] | undefined
          if (pages && Array.isArray(pages)) {
            for (const page of pages) {
              hits = hits || []
              found = found || []
              groupedHits = groupedHits || []
              if (page.hits && Array.isArray(page.hits)) {
                hits.push(...page.hits)
              }
              found.push(page.found)
              if (page.grouped_hits) {
                groupedHits.push(...page.grouped_hits)
              }
            }
          }
          return { hits, found, groupedHits }
        }

        const previousHits = getComparableValues(prev)
        const nextHits = getComparableValues(next)

        const areHitsEqual = deepEqual(previousHits, nextHits)

        if (areHitsEqual && __DEV__) {
          const wouldHaveReRendered = !deepEqual(prev, next)
          if (wouldHaveReRendered) {
            console.log(
              `[use-typesense-infinite] [${index}] prevented re-render.`
            )
          }
        }

        return areHitsEqual
      },
      ...config,
      limit,
      dataPath: 'hits',
    }
  )

  const revalidateRef = useRef(response.revalidate)
  revalidateRef.current = response.revalidate

  const previousApiKey = useRef(apiKey)
  useEffect(
    function revalidateWhenApiKeyChanges() {
      if (apiKey && query !== null) {
        if (apiKey !== previousApiKey.current) {
          previousApiKey.current = apiKey
          revalidateRef.current()
        }
      }
    },
    [apiKey, query]
  )

  const { pages } = response

  const groups = useMemo(() => {
    if (!groupBy) return undefined

    let groups: Record<string, Array<SearchResponseHit<T>>> | undefined

    for (const page of pages || []) {
      if (page.grouped_hits) {
        groups = groups || {}
        for (const group of page.grouped_hits) {
          const { hits, group_key } = group

          if (group_key.length == 0) {
            console.warn(
              '[use-typesense-search-infinite] empty group_key found. skipping.'
            )
          } else {
            groups[group_key.join(',')] = hits
          }
        }
      }
    }

    return groups
  }, [groupBy, pages])

  return { ...response, groups }
}

export { useTypesenseSearchInfinite }
