import { CloudinaryImageSmall } from '@beatgig/api/image'

import { Image } from '@beatgig/api-services'
import cloudinary from 'cloudinary-tiny-js'
import { CloudinaryImage } from './types'
import { ImageSmall } from '@beatgig/api-services/image'
import { CLOUDINARY_CLOUD_NAME } from './name'
import { ImageCamelFragment } from '@beatgig/gql'

const client = cloudinary({ cloudName: CLOUDINARY_CLOUD_NAME })

function Cloudinary(
  image?:
    | Image
    | ImageSmall
    | CloudinaryImageSmall
    | (Pick<ImageCamelFragment, 'publicCloudinaryId'> &
        Partial<ImageCamelFragment>),
  // we want to enforce size, not "width", so this change is to break the types.
  { quality = 75, width = 600, size, ...options }: CloudinaryImage = {}
): string | undefined {
  if (!image) return

  let publicCloudinaryId: string | undefined
  if ('publicCloudinaryId' in image) {
    publicCloudinaryId = image.publicCloudinaryId
  } else if ('public_cloudinary_id' in image) {
    publicCloudinaryId = image.public_cloudinary_id
  }

  if (!publicCloudinaryId && 'url' in image) {
    return image.url
  }

  // we want to shrink the smaller of the two values
  let sizeKey = 'width'
  if (
    image &&
    'height' in image &&
    'width' in image &&
    (image.height || 0) < (image.width || 0)
  ) {
    sizeKey = 'height'
  }

  return client(publicCloudinaryId, {
    quality,
    ...options,
    // TODO change width to "maxSize"?
    [sizeKey]: width ? Number(width).toFixed(0) : undefined,
  }) as string
}

type Cloudinary = (url: string | Image, options: CloudinaryImage) => string

export default Cloudinary
